import { useState, useEffect } from "react";
import Axios from "axios";

const useAPI = (endpoint) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Axios.get(endpoint);
                //console.log(response);

                setData(response.data);
                //console.log(response.data);
            } catch (error) {
                //console.log(error);
            }
        };
        const id = setInterval(() => {
            fetchData();
        }, 300000); // Time interval reload data (5 min.)

        fetchData();

        return () => clearInterval(id);
        // eslint-disable-next-line
    }, []);

    //To call data when component is mounted,
    /*   useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = async () => {
    const response = await Axios.get(endpoint);
    setData(response.data);
  }; */

    return data;
};

export default useAPI;
