import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    info: {
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(2),
        padding: theme.spacing(2, 0),
    },
}));

export default function Info(props) {
    const classes = useStyles();
    const { name, address, contact, web, social, hours, message } = props;

    return (
        <div className={classes.info}>
            <Container maxWidth="lg">
                {/* <Logo /> */}
                <Typography variant="h4" align="center" gutterBottom>
                    {name}
                </Typography>
                <Typography
                    variant="h6"
                    align="center"
                    color="textSecondary"
                    component="p"
                >
                    {address}
                </Typography>
                <Typography
                    variant="h6"
                    align="center"
                    color="textSecondary"
                    component="p"
                    gutterBottom
                >
                    {contact}
                </Typography>
                <Typography
                    variant="h6"
                    align="center"
                    color="textSecondary"
                    component="p"
                    gutterBottom
                >
                    {web}
                </Typography>

                <Typography
                    variant="h6"
                    align="center"
                    color="textSecondary"
                    component="p"
                    gutterBottom
                >
                    {social}
                </Typography>

                <Typography
                    variant="h6"
                    align="center"
                    color="textSecondary"
                    component="p"
                    gutterBottom
                >
                    {hours}
                </Typography>
                <Typography
                    variant="h6"
                    align="center"
                    color="textSecondary"
                    component="p"
                    gutterBottom
                >
                    {message}
                </Typography>
            </Container>
            {/*       <div>
        {social.map((network) => (
          <Link display="block" variant="body1" href="#" key={network}>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <network.icon />
              </Grid>
              <Grid item>{network.name}</Grid>
            </Grid>
          </Link>
        ))}
      </div> */}
        </div>
    );
}

Info.propTypes = {
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    contact: PropTypes.string.isRequired,
    web: PropTypes.string.isRequired,
    social: PropTypes.string.isRequired,
    hours: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
};
