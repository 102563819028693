import { GET_UNITS } from "./UnitAction";

export const UnitReducer = (state, action) => {
  switch (action.type) {
    case GET_UNITS:
      return {
        ...state,
        units: action.payload,
      };
    default:
      return state;
  }
};

export default UnitReducer;
