import React, { createContext, useReducer } from "react";
import { GET_APPSETTINGS, SET_APPSETTING } from "./AppSettingAction";
import AppSettingReducer from "./AppSettingReducer";

const intialState = {
  appSettings: [],
};

export const AppSettingContext = createContext();

export const AppSettingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppSettingReducer, intialState);

  // Get App Settings
  function getAppSettings(appSettings) {
    dispatch({
      type: GET_APPSETTINGS,
      payload: appSettings,
    });
  }

  // Set App Settings
  function setAppSetting(appSettings) {
    dispatch({
      type: SET_APPSETTING,
      payload: appSettings,
    });
  }

  return (
    <AppSettingContext.Provider
      value={{
        appSettings: state.appSettings,
        getAppSettings,
        setAppSetting,
      }}
    >
      {children}
    </AppSettingContext.Provider>
  );
};
