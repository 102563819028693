import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import backgroundImage from "../resources/static/assets/images/logo-app-bar.png";

const useStyles = makeStyles((theme) => ({
    paper: {
        // position: "relative",
        // height: 315,
        // backgroundColor: theme.palette.grey[800],
        // color: theme.palette.common.white,
        marginTop: theme.spacing(2),
        // marginBottom: theme.spacing(4),
        // backgroundImage: "",
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        // backgroundPosition: "center",
        //backgroundImage: `url(${image})`,
        height: 315,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        //backgroundImage: `url(${backgroundImage})`,
    },
}));

export default function MainImage(props) {
    const classes = useStyles();
    const { image } = props;

    return (
        <div
            className={classes.paper}
            style={{ backgroundImage: `url(${image})` }}
        ></div>
    );
}

MainImage.propTypes = {
    image: PropTypes.string,
};
