import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useHistory } from "react-router-dom";
import Header from "./Header";

const useStyles = makeStyles((theme) => ({
  mainMessage: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(5),
  },
}));

export default function Home() {
  const classes = useStyles();
  const history = useHistory();

  const home = () => history.push("/");

  return (
    <div>
      <Header />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.mainMessage}>
          <Typography align="center" variant="h2" component="div" gutterBottom>
            404
          </Typography>
          <Typography align="center" variant="h5" component="div" gutterBottom>
            Pagina non trovata!
          </Typography>
        </div>
        <Button fullWidth variant="contained" color="primary" onClick={home}>
          Home
        </Button>
      </Container>
    </div>
  );
}
