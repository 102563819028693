import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useContext, useEffect, useState } from "react";
import { AppsContext } from "../../../App";
import ContentProduct from "./ContentProduct";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function ListProduct(props) {
    const classes = useStyles();
    const { state, dispatch } = useContext(AppsContext);
    const counter = state.apps.length - 1;
    const language = state.apps[counter].language;
    const [stateContent, setStateContent] = useState("LIST");
    const [selectedProduct, setSelectedProduct] = useState(null);
    const digitalMenus = props.digitalMenus;
    const categories = props.categories;
    const products = props.products;
    const selectedDigitalMenuCode = props.idSelectedDigitalMenu;
    const selectedCategoryCode = props.idSelectedCategory;
    const digitalMenuHiddenPrices = props.digitalMenuHiddenPrices;
    const units = props.units;
    const productTypes = props.productTypes;
    const allergens = props.allergens;
    const idSelectedDigitalMenu = props.idSelectedDigitalMenu;
    const idSelectedCategory = props.idSelectedCategory;

    // Filtered Products by Menu selected
    let productsResult = products.filter((product) =>
        product.productDigitalMenuCode.includes(selectedDigitalMenuCode)
    );

    // Filtered and sort Products by Category selected
    productsResult = productsResult.filter((product) =>
        product.productCategoryCode.includes(selectedCategoryCode)
    );

    // Sort by alphabetical order
    productsResult = [...productsResult].sort((a, b) => {
        let fa = a["productTitle" + language].toLowerCase(),
            fb = b["productTitle" + language].toLowerCase();
        if (fa < fb) {
            return -1;
        }
        if (fa > fb) {
            return 1;
        }
        return 0;
    });

    // Sort by product position
    productsResult = productsResult.sort(
        (a, b) => a.productPosition - b.productPosition
    );

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const functionDetailProductParent = (product) => {
        props.selectedProductChild(product);
        props.functionChild("DETAIL");
    };

    return (
        <>
            <Container maxWidth="sm">
                <Grid direction="column" alignItems="center">
                    {productsResult.map((product) => (
                        <ContentProduct
                            key={product.productCode}
                            content={product}
                            functionDetailProductChild={
                                functionDetailProductParent
                            }
                            units={units}
                            digitalMenuHiddenPrices={digitalMenuHiddenPrices}
                        />
                    ))}
                </Grid>
            </Container>
        </>
    );
}
