import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../resources/static/assets/images/logo-app-bar.png";

const useStyles = makeStyles((theme) => ({
    box: {
        padding: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

export default function Logo() {
    const classes = useStyles();
    return (
        <Box className={classes.box}>
            <img src={logo} alt="logo" width="105px" height="184px" />
        </Box>
    );
}
