import React, { useReducer } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import Page404 from "./components/Page404";
import { AllergenProvider } from "./context/allergen/AllergenState";
import { AppSettingProvider } from "./context/appsetting/AppSettingState";
import { CategoryProvider } from "./context/category/CategoryState";
import { DigitalMenuProvider } from "./context/digitalmenu/DigitalMenuState";
import { ProductProvider } from "./context/product/ProductState";
import { ProductTypeProvider } from "./context/producttype/ProductTypeState";
import { StoreProvider } from "./context/store/StoreState";
import { UnitProvider } from "./context/unit/UnitState";

const appsInitialState = {
    apps: [
        {
            id: 0,
            language: "IT",
            navigation: "HOME",
            idSelectedDigitalMenu: "1",
            idSelectedCategory: "",
            idSelectedProduct: "",
        },
    ],
};

export const AppsContext = React.createContext();

export default function App() {
    const [state, dispatch] = useReducer(appsReducer, appsInitialState);

    return (
        <AppsContext.Provider value={{ state, dispatch }}>
            <AppSettingProvider>
                <StoreProvider>
                    <DigitalMenuProvider>
                        <CategoryProvider>
                            <ProductProvider>
                                <UnitProvider>
                                    <ProductTypeProvider>
                                        <AllergenProvider>
                                            <BrowserRouter>
                                                <div>
                                                    <Switch>
                                                        {/* <Redirect exact from="/" to="/app" /> */}
                                                        <Route
                                                            exact
                                                            path="/"
                                                            component={Home}
                                                        />
                                                        {/* <Route exact path="/app" component={Home} /> */}
                                                        {/* <Route path="/app/*" component={Page404} /> */}
                                                        <Route
                                                            path="*"
                                                            component={Page404}
                                                        />
                                                    </Switch>
                                                </div>
                                            </BrowserRouter>
                                        </AllergenProvider>
                                    </ProductTypeProvider>
                                </UnitProvider>
                            </ProductProvider>
                        </CategoryProvider>
                    </DigitalMenuProvider>
                </StoreProvider>
            </AppSettingProvider>
        </AppsContext.Provider>
    );
}

function appsReducer(state, action) {
    switch (action.type) {
        case "add":
            const newApp = { id: state[action.id] + 1, ...action.payload };
            const addedApps = [...state.apps, newApp];
            return { ...state, apps: addedApps };
        case "delete":
            const filteredAppState = state.apps.filter(
                (t) => t.id !== action.payload.id
            );
            return { ...state, apps: filteredAppState };
        case "edit":
            const updatedApp = { ...action.payload };
            const updatedAppIndex = state.apps.findIndex(
                (t) => t.id === action.payload.id
            );
            const updatedApps = [
                ...state.apps.slice(0, updatedAppIndex),
                updatedApp,
                ...state.apps.slice(updatedAppIndex + 1),
            ];
            return { ...state, apps: updatedApps };
        default:
            return appsInitialState;
    }
}
