import React, { createContext, useReducer } from "react";
import { GET_DIGITALMENUS } from "./DigitalMenuAction";
import DigitalMenuReducer from "./DigitalMenuReducer";

const intialState = {
  digitalMenus: [],
};

export const DigitalMenuContext = createContext(intialState);

export const DigitalMenuProvider = ({ children }) => {
  const [state, dispatch] = useReducer(DigitalMenuReducer, intialState);

  // Get all digital menus
  function getDigitalMenus(digitalMenus) {
    dispatch({
      type: GET_DIGITALMENUS,
      payload: digitalMenus,
    });
  }

  return (
    <DigitalMenuContext.Provider
      value={{
        digitalMenus: state.digitalMenus,
        getDigitalMenus,
      }}
    >
      {children}
    </DigitalMenuContext.Provider>
  );
};
