import React, { createContext, useReducer } from "react";
import { GET_ALLERGENS } from "./AllergenAction";
import AllergenReducer from "./AllergenReducer";

const intialState = {
  allergens: [],
};

export const AllergenContext = createContext(intialState);

export const AllergenProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AllergenReducer, intialState);

  // Get all allergens
  function getAllergens(allergens) {
    dispatch({
      type: GET_ALLERGENS,
      payload: allergens,
    });
  }

  return (
    <AllergenContext.Provider
      value={{
        allergens: state.allergens,
        getAllergens,
      }}
    >
      {children}
    </AllergenContext.Provider>
  );
};
