import { Fragment, useState } from "react";
import ListCategory from "./ListCategory";

export default function Category(props) {
    const [stateContent, setStateContent] = useState("LIST");
    const [idSelectedCategory, setIdSelectedCategory] = useState(null);
    const idSelectedDigitalMenu = props.idSelectedDigitalMenu;
    const digitalMenus = props.digitalMenus;
    const categories = props.categories;
    const products = props.products;
    const units = props.units;
    const productTypes = props.productTypes;
    const allergens = props.allergens;

    const functionParent = (typeMode) => {
        setStateContent(typeMode);
        props.functionChild("PRODUCT");
    };

    const selectedCategoryParent = (id) => {
        setIdSelectedCategory(id);
    };

    const renderSwitch = (stateContent) => {
        switch (stateContent) {
            case "LIST":
                return (
                    <ListCategory
                        idSelectedDigitalMenu={idSelectedDigitalMenu}
                        idSelectedCategory={idSelectedCategory}
                        selectedCategoryChild={selectedCategoryParent}
                        functionChild={functionParent}
                        categories={categories}
                    />
                );
            default:
                break;
        }
    };

    return <Fragment>{renderSwitch(stateContent)}</Fragment>;
}
