import { GET_APPSETTINGS, SET_APPSETTING } from "./AppSettingAction";

export const AppSettingReducer = (state, action) => {
  switch (action.type) {
    case GET_APPSETTINGS:
      return {
        ...state,
        appSettings: action.payload,
      };

    case SET_APPSETTING:
      /*       const updateAppSetting = action.payload;
      const updateAppSettings = state.appSettings.map((appSetting) => {
        if (appSetting.id === updateAppSetting.id) {
          return updateAppSetting;
        }

        return appSetting;
      }); */

      const updateAppSetting = { ...action.payload };
      const updateAppSettingIndex = state.appSettings.findIndex(
        (t) => t.id === action.payload.id
      );
      const updateAppSettings = [
        ...state.appSettings.slice(0, updateAppSettingIndex),
        updateAppSetting,
        ...state.appSettings.slice(updateAppSettingIndex + 1),
      ];

      return {
        ...state,
        appSettings: updateAppSettings,
      };
    default:
      return state;
  }
};

export default AppSettingReducer;
