import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../resources/static/assets/images/logo-app-bar.png";

const useStyles = makeStyles((theme) => ({
    image: {
        //height: "40",
        //width: "100",
        //position: "relative",
        //backgroundColor: theme.palette.grey[800],
        //color: theme.palette.common.white,
        //padding: theme.spacing(0.5),
        marginTop: theme.spacing(0.5),
        //marginBottom: theme.spacing(4),
        //backgroundSize: "cover",
        //backgroundRepeat: "no-repeat",
        //backgroundPosition: "center",
    },
}));

export default function LogoAppBar() {
    const classes = useStyles();
    return (
        <div>
            <Box className={classes.image}>
                <img src={logo} alt="logo" width="80" height="50" />
            </Box>
        </div>
    );
}
