import React, { createContext, useReducer } from "react";
import { GET_PRODUCTS } from "./ProductAction";
import ProductReducer from "./ProductReducer";

const intialState = {
  products: [],
};

export const ProductContext = createContext(intialState);

export const ProductProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProductReducer, intialState);

  // Get all products
  function getProducts(products) {
    dispatch({
      type: GET_PRODUCTS,
      payload: products,
    });
  }

  return (
    <ProductContext.Provider
      value={{
        products: state.products,
        getProducts,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};
