import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useContext, useEffect } from "react";
import { AppsContext } from "../../../App";
import ContentCategory from "./ContentCategory";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
    cardGrid: {
        paddingTop: theme.spacing(2),
        //paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
        //backgroundColor: theme.palette.background.paper,
    },
}));

export default function ListCategory(props) {
    const classes = useStyles();
    const { state, dispatch } = useContext(AppsContext);
    const counter = state.apps.length - 1;
    const language = state.apps[counter].language;
    const categories = props.categories;
    const selectedDigitalMenuCode = props.idSelectedDigitalMenu;

    // Filtered Categories by Digital Menu selected
    let categoriesResult = categories.filter((category) =>
        category.categoryDigitalMenuCode.includes(selectedDigitalMenuCode)
    );

    // Sort by alphabetical order
    categoriesResult = [...categoriesResult].sort((a, b) => {
        let fa = a["categoryDescription" + language].toLowerCase(),
            fb = b["categoryDescription" + language].toLowerCase();
        if (fa < fb) {
            return -1;
        }
        if (fa > fb) {
            return 1;
        }
        return 0;
    });

    // Sort by category position
    categoriesResult = categoriesResult.sort(
        (a, b) => a.categoryPosition - b.categoryPosition
    );

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const functionListProductsParent = (categoryCode) => {
        props.functionChild("PRODUCT");
        props.selectedCategoryChild(categoryCode);
    };

    return (
        <>
            <Container maxWidth="sm">
                <Grid direction="column" alignItems="center">
                    {categoriesResult.map((category) => (
                        <ContentCategory
                            key={category.categoryCode}
                            content={category}
                            selectedDigitalMenuCode={selectedDigitalMenuCode}
                            functionListProductsChild={
                                functionListProductsParent
                            }
                        />
                    ))}
                </Grid>
            </Container>
        </>
    );
}
