import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { useContext } from "react";
import { AppsContext } from "../../../App";

const useStyles = makeStyles((theme) => ({
    cardGrid: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
    },
    card: {
        width: "100%",
        height: "100%",
        display: "flex",
        borderRadius: "0%",
    },
    cardDetails: {
        flex: 1,
    },
    cardMedia: {
        margin: theme.spacing(3),
        width: 150,
        height: 150,
        //paddingRight: "30%",
    },
    cardContent: {
        flexGrow: 1,
        paddingBottom: "0px",
    },
    contentTitle: {},
    contentDescription: { height: 100 },
    contentInfo: {},
}));

export default function ContentDigitalMenu(props) {
    const classes = useStyles();
    const { content } = props;
    const { state, dispatch } = useContext(AppsContext);
    const counter = state.apps.length - 1;
    const language = state.apps[counter].language;
    const navigation = state.apps[counter].navigation;
    const idSelectedDigitalMenu = state.apps[counter].idSelectedDigitalMenu;
    const idSelectedCategory = state.apps[counter].idSelectedCategory;
    const idSelectedProduct = state.apps[counter].idSelectedProduct;

    const handleListCategories = (digitalMenuCode) => () => {
        const nav = "CATEGORY";
        const menu = digitalMenuCode;
        dispatch({
            type: "add",
            payload: {
                id: counter + 1,
                language: language,
                navigation: nav,
                idSelectedDigitalMenu: menu,
                idSelectedCategory: idSelectedCategory,
                idSelectedProduct: idSelectedProduct,
            },
        });
        props.functionListCategoriesChild(digitalMenuCode);
    };

    return (
        <Container className={classes.cardGrid} maxWidth="md">
            <Grid item>
                {/* <CardActionArea component={Link} to={`${url}/categories`}> */}
                <CardActionArea>
                    <Card
                        className={classes.card}
                        onClick={handleListCategories(content.digitalMenuCode)}
                    >
                        <div className={classes.cardDetails}>
                            <CardContent className={classes.cardContent}>
                                <div className={classes.contentTitle}>
                                    <Typography
                                        variant="h4"
                                        component="div"
                                        gutterBottom
                                    >
                                        {content.digitalMenuTitle}
                                    </Typography>
                                </div>
                                <div className={classes.contentDescription}>
                                    <Typography
                                        variant="body1"
                                        color="textSecondary"
                                        component="p"
                                    >
                                        {content.digitalMenuDescription}
                                    </Typography>
                                </div>
                                <div className={classes.contentInfo}></div>
                            </CardContent>
                        </div>
                        {content.digitalMenuImageURL ? (
                            <CardMedia
                                className={classes.cardMedia}
                                image={content.digitalMenuImageURL}
                            />
                        ) : null}
                    </Card>
                </CardActionArea>
            </Grid>
        </Container>
    );
}

ContentDigitalMenu.propTypes = {
    content: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.string,
    }),
};
