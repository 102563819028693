import { GET_STORES } from "./StoreAction";

export const StoreReducer = (state, action) => {
  switch (action.type) {
    case GET_STORES:
      return {
        ...state,
        stores: action.payload,
      };
    default:
      return state;
  }
};

export default StoreReducer;
