import React, { createContext, useReducer } from "react";
import { GET_CATEGORIES } from "./CategoryAction";
import CategoryReducer from "./CategoryReducer";

const intialState = {
  categories: [],
};

export const CategoryContext = createContext(intialState);

export const CategoryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CategoryReducer, intialState);

  // Get all categories
  function getCategories(categories) {
    dispatch({
      type: GET_CATEGORIES,
      payload: categories,
    });
  }

  return (
    <CategoryContext.Provider
      value={{
        categories: state.categories,
        getCategories,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};
