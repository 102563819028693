import React, { createContext, useReducer } from "react";
import { GET_UNITS } from "./UnitAction";
import UnitReducer from "./UnitReducer";

const intialState = {
  units: [],
};

export const UnitContext = createContext(intialState);

export const UnitProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UnitReducer, intialState);

  // Get all units
  function getUnits(units) {
    dispatch({
      type: GET_UNITS,
      payload: units,
    });
  }

  return (
    <UnitContext.Provider
      value={{
        units: state.units,
        getUnits,
      }}
    >
      {children}
    </UnitContext.Provider>
  );
};
