import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import {
    createTheme,
    makeStyles,
    ThemeProvider,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import TranslateIcon from "@material-ui/icons/Translate";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { AppsContext } from "../App";
import { AllergenContext } from "../context/allergen/AllergenState";
import { AppSettingContext } from "../context/appsetting/AppSettingState";
import { CategoryContext } from "../context/category/CategoryState";
import { DigitalMenuContext } from "../context/digitalmenu/DigitalMenuState";
import { ProductContext } from "../context/product/ProductState";
import { ProductTypeContext } from "../context/producttype/ProductTypeState";
import { StoreContext } from "../context/store/StoreState";
import { UnitContext } from "../context/unit/UnitState";
import useAPI from "../useAPI";
import Category from "./content/category/Category";
import DigitalMenu from "./content/digitalmenu/DigitalMenu";
import DetailProduct from "./content/product/DetailProduct";
import Product from "./content/product/Product";
import Info from "./Info";
import Language from "./Language";
import LogoAppBar from "./LogoAppBar";
import MainDescription from "./MainDescription";
import MainImage from "./MainImage";

const useStyles = makeStyles((theme) => ({
    main: {
        //paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(10),
        marginLeft: theme.spacing(-1),
        marginRight: theme.spacing(-1),
        //paddingRight: theme.spacing(10),
        //padding: theme.spacing(20, 0),
    },
    stickToBottom: {
        width: "100%",
        position: "fixed",
        bottom: 0,
    },
    stickToTop: {
        width: "100%",
        //position: "fixed",
        top: 0,
        marginBottom: theme.spacing(3),
    },
    cardGrid: {
        paddingTop: theme.spacing(1),
    },
    card: {
        paddingTop: 8,
        width: "100%",
        height: "100%",
        textAlign: "center",
    },
    cardDetails: {
        backgroundPosition: "center",
        flex: 1,
    },
}));

// Link
const sections = [];

export default function Home() {
    const classes = useStyles();

    // Custom theme
    const theme = createTheme({
        palette: {
            background: {
                default: "#eceff1",
            },
        },
    });

    // Context
    const { state, dispatch } = useContext(AppsContext);
    const { appSettings, getAppSettings } = useContext(AppSettingContext);
    const { stores, getStores } = useContext(StoreContext);
    const { digitalMenus, getDigitalMenus } = useContext(DigitalMenuContext);
    const { categories, getCategories } = useContext(CategoryContext);
    const { products, getProducts } = useContext(ProductContext);
    const { units, getUnits } = useContext(UnitContext);
    const { productTypes, getProductTypes } = useContext(ProductTypeContext);
    const { allergens, getAllergens } = useContext(AllergenContext);

    // Stack
    let counter = state.apps.length - 1;
    let language = state.apps[counter].language;
    let navigation = state.apps[counter].navigation;
    let idSelectedDigitalMenu = state.apps[counter].idSelectedDigitalMenu;
    let idSelectedCategory = state.apps[counter].idSelectedCategory;
    let idSelectedProduct = state.apps[counter].idSelectedProduct;
    const [appLanguage, setAppLanguage] = useState(
        state.apps[counter].language
    );
    const [stateContent, setStateContent] = useState(
        state.apps[counter].navigation
    );

    // Menu Label and Category label at the top of the list
    let digitalMenusResult;
    let digitalMenuTitle;
    let categoryResult;
    let categoryDescription;

    // "App Setting"
    const endpointAppSetting =
        process.env.REACT_APP_ENDPOINT_API + "/app_settings/get";
    const savedAppSettings = useAPI(endpointAppSetting);

    // "Store"
    const endpointStore = process.env.REACT_APP_ENDPOINT_API + "/stores";
    const storeStatus = true;
    const filterStore = "/getbystatus/" + storeStatus;
    const savedStores = useAPI(endpointStore + filterStore);

    // "Digital menu"
    const endpointDigitalMenu =
        process.env.REACT_APP_ENDPOINT_API + "/digital_menus";
    const digitalMenuStatus = true;
    const filterDigitalMenu = "/getbystatus/" + digitalMenuStatus;
    const savedDigitalMenus = useAPI(endpointDigitalMenu + filterDigitalMenu);

    // "Category"
    const endpointCategory = process.env.REACT_APP_ENDPOINT_API + "/categories";
    const categoryStatus = true;
    const filterCategory = "/getbystatus/" + categoryStatus;
    const savedCategories = useAPI(endpointCategory + filterCategory);
    console.log(endpointCategory + filterCategory);

    // "Product"
    const endpointProduct = process.env.REACT_APP_ENDPOINT_API + "/products";
    const productStatus = true;
    const filterProduct = "/getbystatus/" + productStatus;
    const savedProducts = useAPI(endpointProduct + filterProduct);

    // "Unit"
    const endpointUnit = process.env.REACT_APP_ENDPOINT_API + "/units";
    let filterUnit = "/get";
    const savedUnits = useAPI(endpointUnit + filterUnit);

    // "Product Type"
    const endpointProductType =
        process.env.REACT_APP_ENDPOINT_API + "/product_types";
    let filterProductType = "/get";
    const savedProductTypes = useAPI(endpointProductType + filterProductType);

    // Allergen
    const endpointAllergen = process.env.REACT_APP_ENDPOINT_API + "/allergens";
    let filterAllergen = "/get";
    const savedAllergens = useAPI(endpointAllergen + filterAllergen);

    useEffect(() => {
        getAppSettings(savedAppSettings);
        getStores(savedStores);
        getDigitalMenus(savedDigitalMenus);
        getCategories(savedCategories);
        getProducts(savedProducts);
        getUnits(savedUnits);
        getProductTypes(savedProductTypes);
        getAllergens(savedAllergens);
        // eslint-disable-next-line
    }, [
        savedAppSettings,
        savedStores,
        savedDigitalMenus,
        savedCategories,
        savedProducts,
        savedUnits,
        savedProductTypes,
        savedAllergens,
    ]);

    // console.log(savedCategories);

    // Description
    // if (idSelectedDigitalMenu) {
    //     digitalMenusResult = digitalMenus.filter((digitalMenu) =>
    //         digitalMenu.digitalMenuCode.includes(idSelectedDigitalMenu)
    //     );
    //     digitalMenuTitle = digitalMenusResult[0].digitalMenuTitle;
    // }

    if (idSelectedCategory) {
        categoryResult = categories.filter((category) =>
            category.categoryCode.includes(idSelectedCategory)
        );
        categoryDescription =
            categoryResult[0]["categoryDescription" + language];
    }

    const handleClickTopAppBar = (event, newValue) => {
        switch (newValue) {
            case "LANGUAGE":
                dispatch({
                    type: "add",
                    payload: {
                        id: counter + 1,
                        language: language,
                        navigation: newValue,
                        idSelectedDigitalMenu: idSelectedDigitalMenu,
                        idSelectedCategory: idSelectedCategory,
                        idSelectedProduct: idSelectedProduct,
                    },
                });
                setStateContent(newValue);
                break;
            case "HOME":
                dispatch({
                    type: "add",
                    payload: {
                        id: counter + 1,
                        language: language,
                        navigation: newValue,
                        idSelectedDigitalMenu: idSelectedDigitalMenu,
                        idSelectedCategory: idSelectedCategory,
                        idSelectedProduct: idSelectedProduct,
                    },
                });
                setStateContent(newValue);
                refreshPage();
                break;
            case "INFO":
                dispatch({
                    type: "add",
                    payload: {
                        id: counter + 1,
                        language: language,
                        navigation: newValue,
                        idSelectedDigitalMenu: idSelectedDigitalMenu,
                        idSelectedCategory: idSelectedCategory,
                        idSelectedProduct: idSelectedProduct,
                    },
                });
                setStateContent(newValue);
                break;
            default:
                setStateContent(newValue);
                break;
        }
    };

    const handleClickBottomAppBar = (event, newValue) => {
        // console.log("Bottom " + newValue, navigation);

        switch (newValue) {
            case "BACK":
                if (counter > 0) {
                    dispatch({
                        type: "delete",
                        payload: { id: counter },
                    });
                    language = state.apps[counter - 1].language;
                    navigation = state.apps[counter - 1].navigation;
                    idSelectedDigitalMenu =
                        state.apps[counter - 1].idSelectedDigitalMenu;
                    idSelectedCategory =
                        state.apps[counter - 1].idSelectedCategory;
                    idSelectedProduct =
                        state.apps[counter - 1].idSelectedProduct;
                    setStateContent(navigation);
                }
                break;

            case "CATEGORY":
                dispatch({
                    type: "add",
                    payload: {
                        id: counter + 1,
                        language: language,
                        navigation: newValue,
                        idSelectedDigitalMenu: idSelectedDigitalMenu,
                        idSelectedCategory: idSelectedCategory,
                        idSelectedProduct: idSelectedProduct,
                    },
                });
                setStateContent(newValue);
                break;

            case "HOME":
                dispatch({
                    type: "add",
                    payload: {
                        id: counter + 1,
                        language: language,
                        navigation: newValue,
                        idSelectedDigitalMenu: idSelectedDigitalMenu,
                        idSelectedCategory: idSelectedCategory,
                        idSelectedProduct: idSelectedProduct,
                    },
                });
                setStateContent(newValue);
                refreshPage();
                break;

            // case "PRENOTA":
            //     setStateContent(newValue);
            //     break;

            default:
                setStateContent(newValue);
                break;
        }
    };

    const functionParent = (typeMode) => {
        setStateContent(typeMode);
    };

    const changeAppLanguageParent = (language) => {
        //setAppLanguage(language);
        //console.log(appLanguage);
    };

    const refreshPage = () => {
        //window.location.reload();
    };

    const goBack = () => {
        //window.history.back();
    };

    const renderSwitch = (stateContent) => {
        switch (stateContent) {
            case "HOME":
                return (
                    <>
                        <MainImage
                            image={
                                stores[0] ? stores[0].storeHomeImageURL : null
                            }
                        />
                        {/* Description */}
                        <MainDescription
                            title={stores[0] ? stores[0].storeHomeTitle : null}
                            description={
                                stores[0]
                                    ? stores[0].storeHomeDescription
                                    : null
                            }
                        />
                    </>
                );
            case "MENU":
                return (
                    <>
                        {/* <MainImage image={stores[0].storeHomeImageURL} /> */}
                        {/* Digital Menus list */}
                        <DigitalMenu
                            digitalMenus={digitalMenus}
                            categories={categories}
                            products={products}
                            units={units}
                            productTypes={productTypes}
                            allergens={allergens}
                            functionChild={functionParent}
                        />
                    </>
                );
            case "CATEGORY":
                return (
                    <>
                        {/* Categories list */}
                        <Category
                            digitalMenus={digitalMenus}
                            idSelectedDigitalMenu={idSelectedDigitalMenu}
                            categories={categories}
                            products={products}
                            units={units}
                            productTypes={productTypes}
                            allergens={allergens}
                            functionChild={functionParent}
                        />
                    </>
                );
            case "PRODUCT":
                return (
                    <>
                        {/* {console.log(idSelectedCategory)}*/}
                        {/* {console.log(categories[idSelectedCategory - 1])} */}
                        <Container className={classes.cardGrid} maxWidth="sm">
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography variant="h5" align="center">
                                        {categoryDescription}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Container>
                        {/* Products list */}
                        <Product
                            digitalMenus={digitalMenus}
                            idSelectedDigitalMenu={idSelectedDigitalMenu}
                            categories={categories}
                            idSelectedCategory={idSelectedCategory}
                            products={products}
                            units={units}
                            productTypes={productTypes}
                            allergens={allergens}
                            functionChild={functionParent}
                        />
                    </>
                );
            case "DETAIL":
                return (
                    <>
                        {/* Detail Product */}
                        <DetailProduct
                            digitalMenus={digitalMenus}
                            idSelectedDigitalMenu={idSelectedDigitalMenu}
                            idSelectedProduct={idSelectedProduct}
                            products={products}
                            units={units}
                            productTypes={productTypes}
                            allergens={allergens}
                            functionChild={functionParent}
                        />
                    </>
                );
            case "LANGUAGE":
                return (
                    <>
                        {/* <MainImage /> */}
                        {/* Language selection */}
                        <Language
                            appSettings={appSettings}
                            appLanguage={appLanguage}
                            changeAppLanguageChild={changeAppLanguageParent}
                            functionChild={functionParent}
                        />
                    </>
                );
            case "INFO":
                return (
                    <>
                        <MainImage image={stores[0].storeHomeImageURL} />
                        {/* Info and Contacts */}
                        <Info
                            name={stores[0] ? stores[0].storeDescription : null}
                            address={
                                (stores[0] ? stores[0].storeAddress : null) +
                                " " +
                                (stores[0] ? stores[0].storeNumber : null) +
                                " - " +
                                (stores[0] ? stores[0].storeZipCode : null) +
                                " " +
                                (stores[0] ? stores[0].storeCity : null) +
                                " (" +
                                (stores[0] ? stores[0].storeProvince : null) +
                                ")"
                            }
                            contact={
                                "Telefono: " +
                                (stores[0] ? stores[0].storePhone : null) +
                                " - " +
                                "Email: " +
                                (stores[0] ? stores[0].storeEmail : null)
                            }
                            web={stores[0] ? stores[0].storeWeb : null}
                            social={stores[0] ? stores[0].storeSocial : null}
                            hours={stores[0] ? stores[0].storeHours : null}
                            message={
                                stores[0] ? stores[0].storeInfoMessage : null
                            }
                        />
                    </>
                );
            default:
                break;
        }
    };

    return (
        <>
            <React.Fragment>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <BottomNavigation
                        component={Paper}
                        elevation={3}
                        value={stateContent}
                        onChange={handleClickTopAppBar}
                        showLabels
                        className={classes.stickToTop}
                    >
                        <BottomNavigationAction
                            label="Lingua"
                            value="LANGUAGE"
                            icon={<TranslateIcon />}
                        />
                        <BottomNavigationAction
                            label=""
                            value="HOME"
                            icon={<LogoAppBar />}
                        />
                        <BottomNavigationAction
                            label="Contatti"
                            value="INFO"
                            icon={<InfoIcon />}
                        />
                    </BottomNavigation>
                    <Container maxWidth="lg">
                        <main className={classes.main}>
                            <Fragment>{renderSwitch(stateContent)}</Fragment>
                        </main>
                    </Container>
                    <BottomNavigation
                        component={Paper}
                        elevation={3}
                        value={stateContent}
                        onChange={handleClickBottomAppBar}
                        showLabels
                        className={classes.stickToBottom}
                    >
                        <BottomNavigationAction
                            label="Indietro"
                            value="BACK"
                            icon={<ArrowBackIcon />}
                        />
                        <BottomNavigationAction
                            label="Menù"
                            value="CATEGORY"
                            icon={<MenuBookIcon />}
                        />
                        <BottomNavigationAction
                            label="Home"
                            value="HOME"
                            icon={<HomeIcon />}
                        />
                        <BottomNavigationAction
                            label="Prenota"
                            value="PRENOTA"
                            icon={<EventAvailableIcon />}
                            // href="https://locandadegliscaligeri.plateform.app/"
                            // href="https://hosteriapuntorosa.plateform.app/"
                            // href="https://lavecchiarama.plateform.app/"
                            href="https://lapiazzettaverona.plateform.app/"
                        />
                    </BottomNavigation>
                </ThemeProvider>
            </React.Fragment>
        </>
    );
}
