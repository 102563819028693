import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { AppsContext } from "../../../App";
import ListAllergen from "../allergen/ListAllergen";

const useStyles = makeStyles((theme) => ({
    cardGrid: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
    },
    card: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    cardDetails: {
        flex: 1,
    },
    containerMedia: {
        display: "flex",
        alignItem: "center",
        justifyContent: "center",
    },
    cardMedia: {
        //paddingTop: "56.25%", // 16:9
        marginTop: "20px",
        width: "auto",
        maxHeight: "300px",
    },
    cardContent: {
        flexGrow: 1,
        paddingBottom: "0px",
    },
    avatarAllergen: {
        display: "flex",
        "& > *": {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    },
    avatarSmall: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    avatarMedium: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    detailTitle: {},
    detailDescription: { marginBottom: theme.spacing(2) },
    detailPrice: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(-2),
    },
    custom: {
        textDecoration: "line-through",
        textDecorationColor: "red",
        //letterSpacing: "20px",
        //fontSize: "20px",
        fontStyle: "italic",
        //color: "green",
        //fontWeight: "bold",
    },
}));

export default function DetailProduct(props) {
    const classes = useStyles();
    const [openAllergenDialog, setOpenAllergenDialog] = React.useState(false);
    const { state } = useContext(AppsContext);
    const counter = state.apps.length - 1;
    const language = state.apps[counter].language;

    // Product selected
    const products = props.products;
    const idSelectedProduct = props.idSelectedProduct;
    const productsResult = products.filter((product) =>
        product.productCode.includes(idSelectedProduct)
    );
    const detailProduct = productsResult[0];
    const productForceVisiblePrice = detailProduct.productForceVisiblePrice;

    // Menu selected
    const digitalMenus = props.digitalMenus;
    const idSelectedDigitalMenu = props.idSelectedDigitalMenu;
    const digitalMenusResult = digitalMenus.filter((digitalMenu) =>
        digitalMenu.digitalMenuCode.includes(idSelectedDigitalMenu)
    );
    const productHiddenPrice = digitalMenusResult[0].digitalMenuHiddenPrices;

    // Unit filter
    const productUnitCode = detailProduct.productUnitCode;
    const units = props.units;
    const productUnitResult = units.find(
        (unit) => unit.unitCode === productUnitCode
    );

    // Product Type filter
    const productTypesCode = detailProduct.productTypeCode;
    const productTypes = props.productTypes;
    const productTypesResult = productTypes.filter(
        (type) => productTypesCode.indexOf(type.productTypeCode) !== -1
    );

    // Allergen filter
    const productAllergens = detailProduct.productAllergenCode;
    const allergens = props.allergens;
    const allergensResult = allergens.filter(
        (allergen) => productAllergens.indexOf(allergen.allergenCode) !== -1
    );

    const handleClickOpenAllergenDialog = () => {
        setOpenAllergenDialog(true);
    };

    const handleCloseAllergenDialog = () => {
        setOpenAllergenDialog(false);
    };

    return (
        <Container className={classes.cardGrid} maxWidth="sm">
            <Grid item>
                <Card className={classes.card}>
                    {detailProduct.productImageURL ? (
                        <div className={classes.containerMedia}>
                            <CardMedia
                                className={classes.cardMedia}
                                component="img"
                                image={detailProduct.productImageURL}
                                //component="video"
                                //autoPlay
                                //controls
                                //src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/WeAreGoingOnBullrun.mp4"
                            />
                        </div>
                    ) : null}
                    <div className={classes.cardDetails}>
                        <CardContent className={classes.cardContent}>
                            <div className={classes.detailTitle}>
                                <Typography
                                    variant="h6"
                                    component="div"
                                    gutterBottom
                                >
                                    {detailProduct["productTitle" + language]}
                                </Typography>
                            </div>
                            <div className={classes.detailDescription}>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                    gutterBottom
                                >
                                    {
                                        detailProduct[
                                            "productDescription" + language
                                        ]
                                    }
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                    gutterBottom
                                >
                                    {
                                        detailProduct[
                                            "productIngredients" + language
                                        ]
                                    }
                                </Typography>
                            </div>
                            {!productHiddenPrice ? <Divider /> : null}
                            {productTypesResult.length ? (
                                <>
                                    <div>
                                        {productTypesResult.map(
                                            (productType) =>
                                                productType[
                                                    "productTypeDescription" +
                                                        language
                                                ]
                                        )}
                                    </div>
                                    <Divider />
                                </>
                            ) : (
                                <></>
                            )}
                            {allergensResult.length ? (
                                <>
                                    <div className={classes.avatarAllergen}>
                                        {allergensResult.map((allergen) => (
                                            <IconButton
                                                onClick={
                                                    handleClickOpenAllergenDialog
                                                }
                                            >
                                                <Avatar
                                                    className={
                                                        classes.avatarMedium
                                                    }
                                                    key={allergen.allergenCode}
                                                    alt={
                                                        allergen[
                                                            "allergenDescription" +
                                                                language
                                                        ]
                                                    }
                                                    src={
                                                        allergen.allergenImageURL
                                                    }
                                                />
                                            </IconButton>
                                        ))}
                                        <ListAllergen
                                            allergens={allergens}
                                            language={language}
                                            open={openAllergenDialog}
                                            onClose={handleCloseAllergenDialog}
                                        />
                                    </div>
                                    <Divider />
                                </>
                            ) : (
                                <></>
                            )}
                            <div className={classes.detailPrice}>
                                {productHiddenPrice &&
                                !productForceVisiblePrice ? null : detailProduct.productDiscountedPrice ? (
                                    <>
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            className={classes.custom}
                                            gutterBottom
                                        >
                                            {"€ "}
                                            {detailProduct.productPrice
                                                ? detailProduct.productPrice.toLocaleString(
                                                      "it-IT",
                                                      {
                                                          minimumFractionDigits: 2,
                                                      }
                                                  )
                                                : "0,00"}
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            component="div"
                                        >
                                            {"€ "}
                                            {detailProduct.productDiscountedPrice
                                                ? detailProduct.productDiscountedPrice.toLocaleString(
                                                      "it-IT",
                                                      {
                                                          minimumFractionDigits: 2,
                                                      }
                                                  )
                                                : "0,00"}
                                        </Typography>
                                    </>
                                ) : (
                                    <Typography variant="h4" component="div">
                                        {"€ "}
                                        {detailProduct.productPrice
                                            ? detailProduct.productPrice.toLocaleString(
                                                  "it-IT",
                                                  {
                                                      minimumFractionDigits: 2,
                                                  }
                                              )
                                            : "0,00"}
                                    </Typography>
                                )}

                                {/* <Typography variant="h6" component="div">
                                    {productUnitResult.unitDescription}
                                </Typography> */}
                            </div>
                        </CardContent>
                    </div>
                </Card>
            </Grid>
        </Container>
    );
}

DetailProduct.propTypes = {
    detailProduct: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        ingredients: PropTypes.string,
        price: PropTypes.string,
        unit: PropTypes.string,
        image: PropTypes.string,
    }),
};
