import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";

import Container from "@material-ui/core/Container";
import ContentDigitalMenu from "./ContentDigitalMenu";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
    cardGrid: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    button: {
        margin: theme.spacing(1),
    },
    table: {
        marginBottom: "10px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

export default function ListDigitalMenu(props) {
    const classes = useStyles();
    const digitalMenus = props.digitalMenus;
    //console.log(digitalMenus);

    useEffect(() => {
        //window.scrollTo(0, 0);
    }, []);

    const functionListCategoriesParent = (digitalMenuCode) => {
        props.functionChild("CATEGORY");
        props.selectedDigitalMenuChild(digitalMenuCode);
    };

    return (
        <>
            <Container maxWidth="md">
                <Grid direction="column" alignItems="center">
                    {digitalMenus.map((digitalMenu) => (
                        <ContentDigitalMenu
                            key={digitalMenu.digitalMenuCode}
                            content={digitalMenu}
                            functionListCategoriesChild={
                                functionListCategoriesParent
                            }
                        />
                    ))}
                </Grid>
            </Container>
        </>
    );
}
