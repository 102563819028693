import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import { useContext, useState } from "react";
import { AppsContext } from "../App";
import China from "../resources/static/assets/images/country/china.png";
import France from "../resources/static/assets/images/country/france.png";
import Germany from "../resources/static/assets/images/country/germany.png";
import Italy from "../resources/static/assets/images/country/italy.png";
import Netherland from "../resources/static/assets/images/country/netherland.png";
import Russia from "../resources/static/assets/images/country/russia.png";
import Spain from "../resources/static/assets/images/country/spain.png";
import UnitedKingdom from "../resources/static/assets/images/country/united-kingdom.png";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
    card: {
        marginTop: theme.spacing(2),
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    listItem: {
        padding: theme.spacing(1),
        //paddingBottom: theme.spacing(1),
    },
}));

const countries = [
    {
        key: 0,
        label: "Italiano",
        src: Italy,
        link: " ",
        value: "IT",
    },
    {
        key: 1,
        label: "Inglese",
        src: UnitedKingdom,
        link: " ",
        value: "EN",
    },
    {
        key: 2,
        label: "Tedesco",
        src: Germany,
        link: " ",
        value: "DE",
    },
    {
        key: 3,
        label: "Francese",
        src: France,
        link: " ",
        value: "FR",
    },
    {
        key: 4,
        label: "Spagnolo",
        src: Spain,
        link: " ",
        value: "ES",
    },
    {
        key: 5,
        label: "Russo",
        src: Russia,
        link: " ",
        value: "RU",
    },
    {
        key: 6,
        label: "Cinese",
        src: China,
        link: " ",
        value: "CN",
    },
    {
        key: 7,
        label: "Olandese",
        src: Netherland,
        link: " ",
        value: "NL",
    },
];

export default function Language(props) {
    const classes = useStyles();
    const { state, dispatch } = useContext(AppsContext);
    //const { appSettings } = useContext(AppSettingContext);
    //const [appSetting, setAppSetting] = useState(state.apps[0].language);
    const counter = state.apps.length - 1;
    const [language, setLanguage] = useState(state.apps[counter].language);

    const handleListItemClick = (e, key) => {
        setLanguage(countries[key].value);
        dispatch({
            type: "edit",
            payload: {
                ...state.apps[counter],
                language: countries[key].value,
            },
        });
        props.functionChild("HOME");
    };

    return (
        <Container maxWidth="sm">
            <Card className={classes.card}>
                <List dense>
                    {countries.map((country, key) => (
                        <ListItem
                            key={country.key}
                            button
                            selected={country.value === language}
                            onClick={(e) => handleListItemClick(e, key)}
                        >
                            <ListItemAvatar className={classes.listItem}>
                                <Avatar alt={country.label} src={country.src} />
                            </ListItemAvatar>
                            <ListItemText primary={country.label} />
                            <ListItemSecondaryAction>
                                {country.value === language ? (
                                    <CheckIcon />
                                ) : null}
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </Card>
        </Container>
    );
}
