import { Fragment, useState } from "react";
import ListDigitalMenu from "./ListDigitalMenu";

export default function DigitalMenu(props) {
    const [stateContent, setStateContent] = useState("LIST");
    const [idSelectedDigitalMenu, setIdSelectedDigitalMenu] = useState(null);
    const digitalMenus = props.digitalMenus;
    const categories = props.categories;
    const products = props.products;
    const units = props.units;
    const productTypes = props.productTypes;
    const allergens = props.allergens;

    const functionParent = (typeMode) => {
        setStateContent(typeMode);
        props.functionChild("CATEGORY");
    };

    const selectedDigitalMenuParent = (id) => {
        setIdSelectedDigitalMenu(id);
    };

    const renderSwitch = (stateContent) => {
        switch (stateContent) {
            case "LIST":
                return (
                    <ListDigitalMenu
                        digitalMenus={digitalMenus}
                        idSelectedDigitalMenu={idSelectedDigitalMenu}
                        selectedDigitalMenuChild={selectedDigitalMenuParent}
                        functionChild={functionParent}
                    />
                );
            /*       case "CATEGORY":
        return (
          <Category
            digitalMenus={digitalMenus}
            idSelectedDigitalMenu={idSelectedDigitalMenu}
            categories={categories}
            products={products}
            units={units}
            productTypes={productTypes}
            allergens={allergens}
            //functionChild={functionParent}
          />
        ); */
            default:
                break;
        }
    };

    return <Fragment>{renderSwitch(stateContent)}</Fragment>;
}
