import Avatar from "@material-ui/core/Avatar";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) => ({
    listItem: {
        padding: 1,
    },
    avatarMedium: {
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
}));

export default function ListAllergen(props) {
    const classes = useStyles();
    const allergens = props.allergens;
    const language = props.language;
    const { onClose, open } = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogTitle id="simple-dialog-title">Elenco allergeni</DialogTitle>
            <List>
                {allergens.map((allergen) => (
                    <ListItem key={allergen.allergenCode}>
                        <ListItemAvatar>
                            <Avatar
                                className={classes.avatarMedium}
                                src={allergen.allergenImageURL}
                            ></Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={allergen["allergenDescription" + language]}
                        />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}

ListAllergen.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};
