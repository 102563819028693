import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    mainDescription: {
        //backgroundColor: theme.palette.background.paper,
        //marginTop: theme.spacing(8),
        marginBottom: theme.spacing(4),
        //padding: theme.spacing(6, 0),
    },
}));

export default function MainDescription(props) {
    const classes = useStyles();
    const { title, description } = props;

    return (
        <div className={classes.mainDescription}>
            <Container maxWidth="lg">
                <Typography variant="h6" align="center" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="subtitle1" align="center" gutterBottom>
                    {description}
                </Typography>
            </Container>
        </div>
    );
}

MainDescription.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
};
