import React, { createContext, useReducer } from "react";
import { GET_PRODUCTTYPES } from "./ProductTypeAction";
import ProductTypeReducer from "./ProductTypeReducer";

const intialState = {
  productTypes: [],
};

export const ProductTypeContext = createContext(intialState);

export const ProductTypeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProductTypeReducer, intialState);

  // Get all productTypes
  function getProductTypes(productTypes) {
    dispatch({
      type: GET_PRODUCTTYPES,
      payload: productTypes,
    });
  }

  return (
    <ProductTypeContext.Provider
      value={{
        productTypes: state.productTypes,
        getProductTypes,
      }}
    >
      {children}
    </ProductTypeContext.Provider>
  );
};
