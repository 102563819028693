import { Fragment, useState } from "react";
import ListProduct from "./ListProduct";

export default function Product(props) {
    const [stateContent, setStateContent] = useState("LIST");
    const [selectedProduct, setSelectedProduct] = useState(null);
    const idSelectedDigitalMenu = props.idSelectedDigitalMenu;
    const idSelectedCategory = props.idSelectedCategory;
    const digitalMenus = props.digitalMenus;
    const categories = props.categories;
    const products = props.products;
    const units = props.units;
    const productTypes = props.productTypes;
    const allergens = props.allergens;
    const digitalMenusResult = digitalMenus.filter((digitalMenu) =>
        digitalMenu.digitalMenuCode.includes(idSelectedDigitalMenu)
    );
    const digitalMenuHiddenPrices =
        digitalMenusResult[0].digitalMenuHiddenPrices;

    const functionParent = (typeMode) => {
        setStateContent(typeMode);
        props.functionChild("DETAIL");
    };

    const selectedProductParent = (product) => {
        setSelectedProduct(product);
    };

    const renderSwitch = (stateContent) => {
        switch (stateContent) {
            case "LIST":
                return (
                    <ListProduct
                        digitalMenus={digitalMenus}
                        idSelectedDigitalMenu={idSelectedDigitalMenu}
                        categories={categories}
                        idSelectedCategory={idSelectedCategory}
                        units={units}
                        products={products}
                        selectedProductChild={selectedProductParent}
                        functionChild={functionParent}
                        digitalMenuHiddenPrices={digitalMenuHiddenPrices}
                    />
                );

            default:
                break;
        }
    };

    return <Fragment>{renderSwitch(stateContent)}</Fragment>;
}
