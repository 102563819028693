import React, { createContext, useReducer } from "react";
import { GET_STORES } from "./StoreAction";
import StoreReducer from "./StoreReducer";

const intialState = {
  stores: [],
};

export const StoreContext = createContext(intialState);

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(StoreReducer, intialState);

  // Get all stores
  function getStores(stores) {
    dispatch({
      type: GET_STORES,
      payload: stores,
    });
  }

  return (
    <StoreContext.Provider
      value={{
        stores: state.stores,
        getStores,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
